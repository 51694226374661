import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"

import { useSelector, useDispatch } from "react-redux"

import ProductsPanier from "../components/productsPanier"
import SEO from "./../components/seo"

import Layout from "../components/layout"

import { setExpress, setSerenite, clearRecapPaiement } from "../actions/panier"
import { getTotalProduits } from "../services/panier"
import { pourcentageExpress, optionSerenite, getRemise } from "../services/prixBaguettes"
import { clearLoading } from "../actions"
import Banner from "../components/banner"
const Panier = (props) => {
  const identifiants = useSelector((state) => state.identifiants)
  const panier = useSelector((state) => state.panierR)

  const dispatch = useDispatch()
  const data = useStaticQuery(graphql`
    query dataPanier {
      allMysqlProduitAnnexe {
        edges {
          node {
            id
            pa_id
            pa_prix_vente_ht
            pa_prix_mini
          }
        }
      }
    }
  `)

  useEffect(() => {
    dispatch(clearRecapPaiement())
    dispatch(clearLoading())
  }, [dispatch])

  if (panier.products.length === 0) {
    if (typeof window !== "undefined") {
      navigate("/")
      return <></>
    }
    return <></>
  }

  const displaySerenite = panier.products
    .reduce(
      (acc, cur) =>
        acc.includes(cur.pageProduit === null ? null : cur.pageProduit.protection)
          ? acc
          : [...acc, cur.pageProduit === null ? null : cur.pageProduit.protection],
      []
    )
    .some((e) => ["verre_normal", "verre_anti_reflet", "verre_invisible", "double_verre_normal", "double_verre_invisible"].includes(e))

  const nextDepartAtelier = (nbDaysMin = 1) => {
    let day = new Date()
    let currentYear = day.getFullYear()
    let joursFeries = [
      new Date(currentYear + 1, "00", "01"),
      new Date(currentYear, "04", "01"),
      new Date(currentYear, "04", "08"),
      new Date(currentYear, "06", "14"),
      new Date(currentYear, "07", "15"),
      new Date(currentYear, "10", "01"),
      new Date(currentYear, "10", "11"),
      new Date(currentYear, "11", "25"),
      new Date(currentYear, "11", "26"),
    ]

    let G = currentYear % 19
    let C = Math.floor(currentYear / 100)
    let H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30
    let I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11))
    let J = (currentYear * 1 + Math.floor(currentYear / 4) + I + 2 - C + Math.floor(C / 4)) % 7
    let L = I - J
    let MoisPaques = 3 + Math.floor((L + 40) / 44)
    let JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4)
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques))
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques - 2))
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques + 1))
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques + 39))
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques + 49))
    joursFeries.push(new Date(currentYear, MoisPaques - 1, JourPaques + 50))
    let dayFound = false

    let nextDayToTry
    let nbDaysTried = 1
    let daysFound = 1
    while (!dayFound) {
      nextDayToTry = new Date(day)
      nextDayToTry.setDate(day.getDate() + nbDaysTried)
      if (![0, 6].includes(nextDayToTry.getDay()) && typeof joursFeries.find((e) => e.getTime() === nextDayToTry.getTime()) === "undefined") {
        if (nbDaysMin <= daysFound) {
          dayFound = true
        }
        daysFound++
      }
      nbDaysTried++
    }
    return nextDayToTry
  }

  const totalProduits = getTotalProduits(panier, data.allMysqlProduitAnnexe.edges)
  let palier = 250
  let reduction = getRemise(250) * 100
  if (totalProduits >= 250) {
    palier = 450
    reduction = getRemise(450) * 100
  }
  return (
    <Layout banner={false} location={props.location}>
      <Banner />
      <SEO title="Mon Panier" description="Votre panier encadrement sur mesure" robots="noindex" />

      <h1>Mon panier</h1>

      <ProductsPanier showQuantite={true} />

      <div className="columns">
        {!panier.express && (
          <div className="column">
            <article className="tile is-child notification is-success" style={{ height: "100%" }}>
              <div className="columns is-vcentered " style={{ height: "112%" }}>
                <div className="column is-narrow">
                  <svg width="100" height="100" className="mr-3" style={{ fill: "white", color: "white" }}>
                    <use xlinkHref="#48h" />
                  </svg>
                </div>
                <div className="column">
                  <p className="title mt-0">
                    Option express{" "}
                    <small style={{ fontSize: "0.8em" }}>
                      (+{" "}
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(totalProduits * (pourcentageExpress - 1))}{" "}
                      )
                    </small>
                  </p>
                  <p className="subtitle">
                    Départ de nos ateliers le{" "}
                    <b>{nextDepartAtelier().toLocaleDateString("fr-FR", { weekday: "long", year: "numeric", month: "long", day: "numeric" })}</b>
                    <br />
                  </p>
                  (sans option express, départ estimé le{" "}
                  {nextDepartAtelier(8).toLocaleDateString("fr-FR", { weekday: "long", year: "numeric", month: "long", day: "numeric" })})
                </div>
                <div className="column is-narrow">
                  <button className="ml-2 button is-large is-success is-light" onClick={() => dispatch(setExpress(true))} data-cy="panier-ajout-express">
                    Ajouter
                  </button>
                </div>
              </div>
            </article>
          </div>
        )}
        {displaySerenite && !panier.serenite && (
          <div className="column">
            <article className="tile is-child notification is-warning">
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <svg width="100" height="100" className="mr-3" style={{ fill: "white", color: "white" }}>
                    <use xlinkHref="#serenite" width="100" height="100" />
                  </svg>
                </div>
                <div className="column">
                  {" "}
                  <p className="title mt-0 mb-1">
                    Option sérénité{" "}
                    <small style={{ fontSize: "0.8em" }}>
                      (+{" "}
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(optionSerenite)}{" "}
                      )
                    </small>
                  </p>
                  <p className="my-0">Malgré nos emballages renforcés et adaptés, un problème de casse lié à la logistique peut toujours se produire.</p>
                  <p className="my-0">
                    L&apos;option sérénité permet le <b>remplacement à l&apos;identique d&apos;un verre cassé</b> même si vous avez déjà accepté le colis auprès
                    du livreur.
                  </p>
                  <p>Le tarif de 1,50€ est appliqué pour la commande complète peu importe le nombre de cadres.</p>
                </div>
                <div className="column is-narrow ">
                  <button className="ml-2 button is-large is-warning is-light" onClick={() => dispatch(setSerenite(true))} data-cy="panier-ajout-serenite">
                    Ajouter
                  </button>
                </div>
              </div>
            </article>
          </div>
        )}
      </div>

      {totalProduits < 450 && (
        <article className="tile is-child notification is-info">
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <svg width="50" height="50" className="mr-3 mt-2" style={{ fill: "white", color: "white" }}>
                <use xlinkHref="#remise" />
              </svg>
            </div>
            <div className="column">
              <p className="subtitle">
                Ajoutez pour{" "}
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(palier - totalProduits)}{" "}
                de produits pour bénéficier de {reduction}% de réduction sur votre panier !
              </p>
            </div>
          </div>
        </article>
      )}

      <Link className="button" to="/cadres/" style={{ marginTop: "1rem" }}>
        Continuer mes achats
      </Link>

      <div className="has-text-right">
        <Link className="button is-large is-primary" to={identifiants.identifiants === null ? "/connexion/?redirect=/apps/commande" : "/apps/commande"}>
          Commander
        </Link>
      </div>
    </Layout>
  )
}

Panier.propTypes = {
  location: PropTypes.object,
}

export default Panier
