import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { updateProduct, removeProduct } from "../actions/panier"
import { graphql, useStaticQuery } from "gatsby"

import { PrevisuPrecoupe, PrevisuCadreComplet, PrevisuPasseSeul } from "@bko/previsus"
import { getPrix } from "../services/panier"
import ImgPa from "./img/imgPa"
import { SousTotaux } from "./sousTotaux"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductsPanier = (props) => {
  const dispatch = useDispatch()
  const panier = useSelector((state) => state.panierR)

  const data = useStaticQuery(graphql`
    query data {
      astralFace: file(relativePath: { eq: "astral-face.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }

      allMysqlProduitAnnexe {
        edges {
          node {
            id
            pa_id
            pa_prix_vente_ht
            pa_prix_mini
          }
        }
      }
    }
  `)
  const handleRemove = (id_product) => {
    dispatch(removeProduct(id_product))
  }

  const handleUpdate = (evt, _id) => {
    evt.preventDefault()
    if (event.target.value != "") {
      const product = panier.products.filter((u) => u._id === _id)[0]

      let qty = 1
      if (event.target.value !== "" && event.target.value > 1) {
        qty = parseInt(event.target.value)
      }

      dispatch(
        updateProduct({
          ...product,
          _id,
          qty: qty,
        })
      )
    }
  }

  return (
    <>
      <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB" }}>
        <div className="panier header column is-4">Produit</div>
        <div style={{ borderLeft: "0" }} className="panier header column is-4">
          Description
        </div>
        <div style={{ borderLeft: "0" }} className="panier header column is-1">
          Prix unitaire (TTC)
        </div>
        <div style={{ borderLeft: "0" }} className={`panier header column is-${props.showQuantite ? 1 : 2}`}>
          Qté
        </div>
        {props.showQuantite && <div style={{ borderLeft: "0" }} className="panier header column is-1" />}
        <div style={{ borderLeft: "0" }} className="panier header column is-1">
          Total (TTC)
        </div>
      </div>

      {panier
        ? panier.products
            .sort((a, b) => a.position - b.position)
            .map((ligne) => {
              const bg =
                typeof ligne.product.pa_passe_hexa !== "undefined"
                  ? parseInt(ligne.product.pa_passe_hexa.replace("#", ""), 16) < parseInt("cccccc", 16)
                    ? "#fff"
                    : "#9f8f7e"
                  : "#FFF"
              return (
                <div key={ligne._id} className="columns is-desktop panier line">
                  <div className="column is-4-desktop">
                    <div
                      className={ligne.product.cog_id === 3 ? "imageProduct backgroundPouBlanc " : ""}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: 100,
                        height: ligne.product.type == "echantillon" ? 100 : "auto",
                      }}
                    >
                      {typeof ligne.product.pa_plaque_longueur !== "undefined" ? ( // passe partout
                        <div style={{ background: bg, padding: 20, margin: "0 auto" }} className="pt-5">
                          <PrevisuPasseSeul
                            product={ligne.product}
                            pageProduit={ligne.pageProduit}
                            couleurInterieur={bg}
                            echantillon={ligne.echantillon}
                            size={200}
                          />
                        </div>
                      ) : typeof ligne.product.pat_id !== "undefined" ? ( // accessoire
                        <ImgPa id={ligne.product.pa_id} style={{ width: 200, height: 200 }} />
                      ) : ligne.echantillon ? (
                        <GatsbyImage className="couleurShadow" alt={"échantillon"} image={getImage(ligne.product.imgCoin)} />
                      ) : ligne.pageProduit.service === "precoupe" ? (
                        <PrevisuPrecoupe
                          largeurFixe={200}
                          imageComponent={GatsbyImage}
                          largeurCadre={ligne.pageProduit.largeur}
                          hauteurCadre={ligne.pageProduit.hauteur}
                          baguette={{ ...ligne.product, imgLongueur: getImage(ligne.product.imgLongueur) }}
                          backgroundPouBlanc={ligne.product.cog_id === 3 ? "#9F8F7E" : "white"}
                        />
                      ) : ligne.product.cat_id === 17 ? (
                        <GatsbyImage style={{ width: 200, height: 200 }} image={getImage(data.astralFace)} alt="Cadre astral" />
                      ) : (
                        <PrevisuCadreComplet
                          product={{ ...ligne.product, imgLongueur: getImage(ligne.product.imgLongueur) }}
                          largeurFixe={200}
                          imageComponent={GatsbyImage}
                          hauteurFixe={200}
                          pageProduit={{
                            ...ligne.pageProduit,
                            doublo:
                              !ligne.pageProduit.doublo
                                ? null
                                : { ...ligne.pageProduit.doublo, imgLongueur: getImage(ligne.pageProduit.doublo.imgLongueur) },
                          }}
                          photos={ligne.photosR.photos}
                        />
                      )}
                    </div>
                  </div>
                  <div className="column is-4-desktop">
                    <p
                      key={`body`}
                      id="___gatsby"
                      dangerouslySetInnerHTML={{
                        __html: ligne.description,
                      }}
                    />
                  </div>
                  <div className="column is-1-desktop fullcenter">
                    <span className="is-hidden-desktop">Prix unitaire :&nbsp;</span>
                    {new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }).format(
                      ligne.echantillon
                        ? ligne.product.prixEchantillon
                        : getPrix(ligne.product, ligne.pageProduit, data.allMysqlProduitAnnexe.edges, ligne.photosR)
                    )}
                  </div>
                  <div className={`column is-${props.showQuantite ? 1 : 2}-desktop fullcenter`}>
                    <span className="is-hidden-desktop">Quantité :&nbsp;</span>
                    {props.showQuantite ? (
                      <input
                        type="number"
                        style={{ marginLeft: "1rem" }}
                        id="quantite"
                        name="quantite"
                        value={ligne.qty}
                        onChange={(e) => handleUpdate(e, ligne._id)}
                        className="input input-number"
                        required
                      />
                    ) : (
                      ligne.qty
                    )}
                    &nbsp;
                  </div>
                  {props.showQuantite && (
                    <div className="column is-1-desktop fullcenter">
                      <button className="button is-danger" onClick={() => handleRemove(ligne._id)} data-cy="products-panier-delete">
                        Supprimer
                      </button>
                    </div>
                  )}
                  <div className="column is-1-desktop fullcenter">
                    <span className="is-hidden-desktop">Total produit TTC :&nbsp;</span>
                    {new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }).format(
                      (ligne.echantillon
                        ? ligne.product.prixEchantillon
                        : getPrix(ligne.product, ligne.pageProduit, data.allMysqlProduitAnnexe.edges, ligne.photosR)) * ligne.qty
                    )}
                  </div>
                </div>
              )
            })
        : null}
      <div className="columns">
        <div className="column is-8"></div>
        <div className="column is-4">
          <SousTotaux editOptions={props.showQuantite} />
        </div>
      </div>
    </>
  )
}

ProductsPanier.propTypes = {
  showQuantite: PropTypes.bool,
}

export default ProductsPanier
